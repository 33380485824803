.section4 {
  color: white;
  margin-bottom: 50px;
  margin-top: 32px;
}

.section4-maincontainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 90%;
  max-width: 1400px;
  margin: auto;
}

.diamond-shape {
  height: 260px;
  text-align: center;
  border: 1.1661px solid #e9ac07;
  box-sizing: border-box;
  border-radius: 20px;
  transform: rotate(-45deg);
  width: 260px;
  margin: 80px;
  margin-bottom: 120px;
}
.item-count {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 46px;
  line-height: 69px;
  /* identical to box height */

  color: #ffc718;
  display: table-cell;
  height: 260px;
  transform: rotate(45deg);
  vertical-align: middle;
  width: 260px;
}

.section4-card {
  position: absolute;
  text-align: justify;
  top: 135px;
  left: -135px;
  transform: rotate(45deg);
  width: 320px;
  height: 175px;
  padding: 20px;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  border-radius: 25px;
  font-size: 20px;
  line-height:32px
}

.section4-border {
  border-bottom: 1px solid #ddac74;
}
.section4-mainheading1 {
  text-align: center;
  margin-bottom: 50px;
  font-family: Eczar;
  font-style: normal;
  font-weight: bold;
  font-size: 75px;
  color: #ffc718;
}

.section4-mainheading2 {
  position: absolute;
  height: 115px;
  left: 677px;
  top: 3263px;
  font-size: 113px;
  /* identical to box height, or 115% */
  display: flex;
  font-family: Eczar;
  font-style: normal;
  font-weight: bold;
  word-spacing: -5px;
  /* identical to box height, or 164% */

  color: rgba(30, 37, 44, 0.0001);

  opacity: 0.1;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ffc718;
}

.number-counter {
  position: absolute;
  width: 104.65px;
  height: 104.65px;
  right: 4px;
  top: 4px;
  background: #0c0c0c;
  border: 1.1661px solid rgba(233, 172, 7, 0.24);
  box-sizing: border-box;
  border-radius: 15px;
}

.number-counter > span {
  font-size: 50px;
  font-weight: 900;
  transform: rotate(45deg);
  display: block;
  width: fit-content;
  margin-top: 9px;
  margin-left: 40px;
}

@media (max-width: 1600px) {
  .section4-mainheading2 {
    left: 572px;
  }
}

@media (max-width: 1382px) {
  .section4-mainheading2 {
    display: none;
  }
}

@media (max-width: 900px) {
  .section4 {
    margin-left: 30px;
    margin-right: 30px;
  }
}

@media (max-width: 800px) {
  .diamond-shape {
    margin-bottom: 140px;
  }
}

@media (max-width: 500px) {
  .section4-mainheading1 {
    font-size: 38px !important;
  }

  .section4-maincontainer {
    width: 100%;
  }

  .diamond-shape {
    margin: 30px;
    margin-bottom: 140px;
  }
}

.section4-image-responsive {
  max-width: 100%;
  height: auto;
}

.section4-image-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
