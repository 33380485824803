.footer-maincontainer{
    margin-top: 150px;
    border-top: 1px solid #ddac74;
    color: white;
}
.navbar-nav-icons-mobile{
    display: none;
}
@media screen and (max-width: 768px) {
    .footer-maincontainer{
        margin-top: 50px;
    }

    .navbar-nav-icons-mobile{
        display: flex;
        flex-direction: row;
        margin:auto;
        width: fit-content;
    }

}