.section5 {
  color: white;
  margin: 32px auto 50px auto;
  width: 85%;
}

.section5 .title-shadow-design,
.section5 .section5-mainheading1 {
  text-align: left;
}

.underline-heading {
  border-top: solid orange 2px;
  width: 100px;
}

.section5-maincontainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 100px;
  justify-content: center;
  padding-bottom: 250px;
}

.section5-main-team {
  position: relative;
}

.section5-main-team .img-container {
  max-width: 430px;
  border-radius: 13.4157px;
  padding: 32px;
  position: absolute;
  top: 10%;
  left: 5%;
}

.section5-main-team .img-container img {
  background: #1e1a1a;
  border-radius: 13.4157px;
  padding: 32px;
  width:100%
}

.img-container h3{
  font-family: Poppins;
  font-weight: bold;
  margin-top: 32px;
  font-size: 25px;
}

.img-container h3 span{
  font-size: 18px;
}

.img-container p{
  font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 18px !important;
line-height: 29px;
text-align: justify;

color: #727272;
}

.section5-main-team h3 span {
  font-size: 20px;
}

.orange-frame {
  width: 235px;
  height: 295px;
  border: solid #ffc718 1px;
  border-radius: 12px;
}

.orange-frame p {
  text-transform: uppercase;
  font-weight: 500;
  margin-top: 10px;
  font-size: 14px;
  margin-left: 32px;
  font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 17px;
letter-spacing: 2.68314px;
text-transform: uppercase;
color: #8D8F91;


}

.section5-border {
  border-bottom: 1px solid #ddac74;
}
.section5-mainheading1 {
  text-align: center;
  margin-bottom: 0;
  font-family: Eczar;
  font-style: normal;
  font-weight: bold;
  font-size: 75px;
  color: #ffc718;
}

@media screen and (max-width: 768px) {
  .section5 {
    margin-top: 50px;
    margin-bottom: 0px;
  }
  .section5 .section5-mainheading1,
  .section5 .title-shadow-design {
    text-align: center;
  }

  .section5 .title-shadow-design {
    font-size: 50px !important;
    line-height: 60px;
  }

  .underline-heading {
    margin: auto;
  }

  .section5-main-team {
    margin-bottom: 350px;
    text-align: center;
  }

  .orange-frame p {
    width: fit-content;
    margin-top: 15px;
  }

  .section5-main-team h3,
  .section5-main-team p {
    width: 100%;
    text-align: left;
    margin: auto;
  }
  
  .section5-main-team h3{
    margin-top: 50px;
  }


  .section5-main-team img {
    width: 100%;
  }

  .orange-frame p {
    width: fit-content;
    margin-top: 15px;
  }

  .orange-frame {
    width: 75%;
    left: 32%;
  }

  .section5-maincontainer{
    padding-bottom: 0px;
  }

  .section5-main-team .img-container img{
    width:280px;
  }
  .img-container{
    left:0px !important;
  }
  .section5-mainheading1{
    font-size: 38px;
  }

  .section5-main-team-m{
    padding-bottom: 32px;
  }
}
