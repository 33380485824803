@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Eczar:wght@700&family=Poppins:wght@300&display=swap);
* {
    margin: 0;
  }
  
.app {
    /* background-color: #111;
    background-image: url('./assets/images/bg1.png'); */
    background: linear-gradient(rgba(19, 19, 19, 0.9),rgba(19, 19, 19, 0.9)
  ), url(/static/media/bg1.6d8208e3.png) no-repeat center center;
  background-size: cover;
  }

  /* html{
    width: fit-content;
  } */

  @media
only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (-o-min-device-pixel-ratio: 3/2),
only screen and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-device-pixel-ratio: 1.5){

  html,
  body{
    width:100%;
    overflow-x:hidden;
  }

}

a{
  text-decoration: none;
}
.bg{
  
  background-image: url(/static/media/bg1.6d8208e3.png);
  opacity: 0.4;
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  -webkit-filter: brightness(25%);
          filter: brightness(25%);
  width: 100%;
  height: 948px;
  position: absolute;
  background-size: cover;
}

@media (max-width: 768px) {

  .title-shadow-design{
    font-size: 8vw !important;
    margin-bottom: -42px !important;
  }
  .section3-maincontainer .title-shadow-design{
    font-size: 7vw !important;
    margin-bottom: -16px !important;
  }
  .section3-para-container1 .title-shadow-design{
    font-size: 8vw !important;
    margin-bottom: -44px !important;
  }
  .section3-heading4,
  .section3-heading2,
  .section2-heading__title2,
  .section4-mainheading1{
    font-size:7vw !important;
  }
}
/* The sticky class is added to the navbar with JS when it
reaches its scroll position */
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  width: 100%;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
}
.navbar {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  z-index: 100;
  /* animations */
  transition-timing-function: ease-in;
  transition: all 0.8s;
  padding: 50px 48px 32px;
  width: 100%;
}

.navbar-background-dark {
  background-color: rgba(17, 17, 17, 0.856);
}

.navbar-nav a {
  color: #fefffb !important;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  margin: 8px 24px 8px 0px;
  padding: 0px;
}

.header-social-link {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  border-radius: 50%;
  transition: all 0.3s;
  font-size: 0.9rem;
  text-decoration: none;
  margin-left: 30px;
  margin-top: 5px;
}

.header-social-link.twitter,
.telegram-plane {
  background-color: #16a2f1;
}

.header-social-link.discord {
  background-color: #7289da;
}

.header-social-link:hover,
.header-social-link:focus {
  background: #b5864f;
  text-decoration: none;
  color: #000 !important;
}

.header-btn {
  background: none;
  cursor: pointer;
  font: 700 1.2rem 'Roboto Slab', sans-serif;
  width:185px;
  height: 56px;
  letter-spacing: 0.05rem;
  border: 0.580038px solid #FFC144 !important;
  border-radius: 30px;
  color: #ffffbc;
  box-shadow: inset 11px 0px 0px -10px #ffc144, inset 11px 0px 0px -10px #ffc144;
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
  font-family: Poppins;
}

.header-btn a {
  color: #ffffbc;

}

.header-btn:focus {
  outline: 2px dotted #b5864f !important;
}

.header-btn:hover {
  
  opacity: 0.8;
  transition: all 0.2s;
}

.navbar-brand {
  color: #ffc718 !important;
  font-family: Eczar;
  font-weight: bold;
  font-size: 25px;
  margin-right: 6rem;
  margin-left: 5rem;
}

.mobile-header-btn {
  display: none;
}

#social-links-header {
  margin-right: 4rem;
  flex-wrap: wrap;
}

#social-links-header a {
  margin-right: 16px;
}

#navbarSupportedContent .navbar-nav {
  flex-wrap: wrap;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 991px) {
  #social-links-header {
    display: none;
  }

  .mobile-header-btn {
    display: inline-block;
    margin: 24px 0px;
  }

  .desktop-header-btn {
    display: none;
  }

  .navbar {
    background-color: rgba(17, 17, 17, 0.9);
    border-radius: 24px;
    padding: 16px 32px;
  }

  .navbar-toggler {
    background-color: #ffc718;
    position: absolute;
    right: 32px;
    top: 16px;
  }
  .sticky {
    border-bottom: 1px solid #ddac74;
  }
  .navbar-brand{
    margin-left: 0px;
  }
}

.section1 {
  color: white;
  object-fit: contain;
  height: 891px;
}

.section1__contents {
  display: flex;
  justify-content: center;
  height: 140px;
}

.section1__description {
  color: #cfa26f;
  text-align: center;
  font-size: 18px;
}

.section1__fadeBottom {
  height: calc(100vh - 140px);
  background-image: linear-gradient(
    180deg,
    transparent,
    rgba(19, 19, 19, 0.30),
    rgba(19, 19, 19, 0.30)
  );
}

.section1-glasseffect {
  margin: 16px;
  margin-top: 550px;
  max-height: 16rem;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 24px;
  z-index: 1;
  background: rgba(11, 11, 11, 0.28);
  border: 2px solid rgba(255, 199, 24, 0.2);
  box-sizing: border-box;
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 14.2839px;
  width: 643px;
  height: 263px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section1-secondsubsection1 {
  max-width: 400px;
  height: 400px;
  position: relative;
  margin: 20px;
}
.section1-secondsubsection1-box1 {
  width: 300px;
  height: 300px;
  position: absolute;
  top: 200px;
  right: 40px;
  opacity: 0.8; /* for demo purpose  */
  /* border: 1.1661px solid #E9AC07; */
  box-sizing: border-box;
  border-radius: 11.661px;

  background-image: url(/static/media/pillar1.1b534b9e.png);
  background-size: contain;
  /* mix-blend-mode: hard-light; */
  opacity: 1;
  z-index: 0;
  background-position: center;
  background-repeat: no-repeat;

  /* -webkit-mask-image: url("../../assets/images/Mask-Group4.png");
mask-image: url("../../assets/images/Mask-Group4.png"); */
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-filter: saturate(150%);
          filter: saturate(150%);
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
}

.section1-secondsubsection1-box2 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: -10px;
  left: -200px;
  /* opacity: 0.8;  for demo purpose  */

  background-image: url(/static/media/Mask-Group3.4ff7ba37.png);
  background-position: center;
  background-repeat: no-repeat;
  width: 792px;
  height: 554px;
  mix-blend-mode: screen;
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
          transform: matrix(-1, 0, 0, 1, 0, 0);

  -webkit-mask-image: url(/static/media/Mask-Group4.a4b5eb1b.png);
  mask-image: url(/static/media/Mask-Group4.a4b5eb1b.png);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;

  -webkit-transform: rotate(-290deg);

          transform: rotate(-290deg);
}

.section1-secondsubsection1-box3 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: -120px;
  left: -20px;

  background-image: url(/static/media/phallus.1b83d692.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  mix-blend-mode: screen;
}

.section1-secondsubsection1-stack-top {
  z-index: 9;
  margin: 20px; /* for demo purpose  */
}

@media (max-width: 436px) {
  .section1-glasseffect {
    width: 100%;
  }

  .section1-secondsubsection1-box1 {
    left: -30px;
  }
}

.section1-square-box1 {
  position: absolute;
  width: 30.23px;
  height: 30.23px;
  /* right: 0px; */
  top: 381px;

  border: 4px solid #ffd44d;
  -webkit-filter: blur(4px);
          filter: blur(4px);
  border-radius: 5px;
  -webkit-transform: rotate(54.58deg);
          transform: rotate(54.58deg);
}

.section1-square-box2 {
  position: absolute;
  width: 70.23px;
  height: 70.23px;
  right: 20px;
  top: 381px;

  border: 8px solid #ffd44d;
  -webkit-filter: blur(5px);
          filter: blur(5px);
  border-radius: 5px;
  -webkit-transform: rotate(54.58deg);
          transform: rotate(54.58deg);
}

@media (max-width: 1000px) {
  .section1-square-box1 {
    display: none;
  }

  .section1-square-box2 {
    display: none;
  }
}

@media (max-width: 480px) {
  .section1{
    height:500px;
  }
}

.Countdown {
  margin: 20px auto;
  padding-bottom: 16px;
}

.Countdown-col {
  display: inline-block;
}

.Countdown-col-element {
  display: inline-block;
  display: flex;
  flex-direction: column;
  width: 115px;
  align-items: center;
}

.Countdown-col-element strong {
  font-size: 65px;
  line-height: 65px;
  font-family: Eczar;
}

.Countdown-col-element span {
  color: #cfa26f;
  font-size: 20px;
  font-family: Poppins;
  font-weight: 700;
}

@media (max-width: 480px) {
  .section1-glasseffect {
    padding: 16px;
    margin-top: 260px;
    height:175px;
  }
  .Countdown-col-element {
    width: 19.5vw;
  }
  .Countdown-col-element strong {
    font-size: 10vw;
  }
  .Countdown-col-element span{
    font-size: 4vw;
  }
  .section1__description{
    font-size: 16px;
  }
  .Countdown{
    margin: 12px auto;
  }
}

.section2 {
  color: white;
  display: flex;
  justify-content: center;
  margin-top: -20px;
}

.section2-main-container {
  width: 80%;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.6);
  padding: 30px;
  background: rgba(0, 0, 0, 0.21);
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  border-radius: 14.2839px;
}

/* HEADING */

.section2-heading {
  text-align: center;
  margin-top: 28px;
}

.title-shadow-design {
  /* MINT LUCKY PHALLUS */
  font-family: Eczar;
  font-weight: bold;
  font-size: 80px;
  word-spacing: -5px;
  color: rgba(30, 37, 44, 0.0001);
  opacity: 0.2;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ffc718;
  margin-bottom: -60px;
  text-align: center;
}

.section3-para-container1 .title-shadow-design {
  font-size: 86px;
  /* margin-left: -32px; */
}

.section4 .title-shadow-design,
.section3-maincontainer .title-shadow-design {
  font-size: 84px;
}
.section2-heading__title2 {
  font-family: Eczar;
  font-style: normal;
  font-weight: bold;
  font-size: 45px;
  line-height: 80px;
  color: #ffc718;
}

.section2-heading__credits {
  transition: all 0.5s;
  color: #c69b6c;
}

.section2-heading__credits span {
  font-size: 22px;
  font-weight: 400;
  line-height: 33px;
}

.section2-heading__credits-contract {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.78);
  opacity: 0.56;
  border: 0.580038px solid #3d3a34;
  box-sizing: border-box;
  box-shadow: 0px 17px 47px rgba(255, 199, 25, 0.15);
  border-radius: 52.2034px;
  width: 140px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  color: #c69b6c;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
}

.section2-heading__credits-contract:hover {
  color: #c69b6c;
}

.section2-heading__credits .heading__link {
  color: inherit;
}

/* CARDS */

.section2-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1090px;
  margin: auto;
}

.section2-card {
  margin-top: 40px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  width: 80%;
  min-height: 200px;
  grid-template-rows: 20px 50px 1fr 50px;
  border-radius: 10px;
  transition: all 0.2s;
}

.section2-card__apply {
  align-self: center;
}

/* section2-CARD BACKGROUNDS */

.section2-card-4 {
  /* background: radial-gradient(#505050, #1a1919); */
  position: relative;
  margin-top: 8px;
  width: 100%;
}

/* RESPONSIVE */

@media (max-width: 1600px) {
  .section2-cards {
    justify-content: center;
  }
}
input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  outline: none;
  background-color: transparent;
  width: 100%;
  color: #b5864f; /* change [input cursor color] by this*/
  text-shadow: 0px 0px 0px #fff; /* change [input font] by this*/
  -webkit-text-fill-color: transparent;
}

.section2-input-container {
  /* border: 2px solid #505050; */
  background-color: #34343471;
  /* display: flex; */
  padding: 24px;
  border-radius: 15px;
  margin: 12px 0px 20px;
}

.section2-input-container h6 {
  color: #c69b6c;
  font-size: 22px;
}

.section2-buttons {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section2-button {
  cursor: pointer;
  color: #ffffbc !important;
  outline: none;
  font-weight: 700;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  max-width: 767px;
  height: 56px;
  margin: auto;
  display: block;
  font-family: Poppins;
  font-size: 18px;
  width: 100%;
  background: rgba(0, 0, 0, 0.78);
  opacity: 0.56;
  border: 0.580038px solid #ffc144;
  box-sizing: border-box;
  box-shadow: 0px 17px 47px rgba(255, 199, 25, 0.15);
  border-radius: 52.2034px;
}

.section2-button:hover {
  box-shadow: 0 0 10px rgb(245, 169, 28), 0 0 40px rgb(245, 169, 28),
    0 0 80px rgb(245, 169, 28);
}

.section2-btn {
  background: none;
  border: none;
  cursor: pointer;
  line-height: 1.5;
  font: 700 1.2rem 'Roboto Slab', sans-serif;
  padding: 0.75em 2em;
  letter-spacing: 0.05rem;
  margin: 0.5em;
  width: 13rem;
  border: 0.58px solid #ffc144 !important;
  border-radius: 30px;
}

.section2-btn i {
  font-size: 1.5rem;
  color: #f4e486;
}

.section2-btn:hover {
  opacity: 0.8;
}

.section-2-text {
  text-align: center;
  font-size: 22px;
  font-family: Poppins;
  font-weight: 600;
  margin: 0px;
  color: #c69b6c !important;
}

.section2-mint-cost h1 {
  color: #f0e49d;
  font-family: Eczar;
}

.section2-mint-cost p {
  color: #b0b0b0;
  font-family: Poppins;
  font-size: 17px;
  line-height: 25px;
}

.section2-sidepillar {
  position: absolute;
  width: 568.56px;
  height: 1907.3px;
  left: -117.69px;
  top: 713px;
  background: url(/static/media/pillar2.c1e2b0e1.png);
  background-repeat: no-repeat;
  -webkit-filter: blur(3px);
          filter: blur(3px);
  -webkit-transform: rotate(7deg);
          transform: rotate(7deg);
  z-index: 10;
}

@media (max-width: 1000px) {
  .section2-sidepillar {
    display: none;
  }
}

.section2-leftside-box {
  /* Vector */

  position: absolute;
  top: calc(50% - 62.5px);
  left: -62.5px;
  width: 125px;
  height: 125px;
  z-index: 10;
  border: 24px solid #ffc718;
  box-shadow: 20px 35px 62px rgba(255, 199, 24, 0.35);
  border-radius: 5px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.section2-rightside-box {
  position: absolute;
  top: calc(50% - 22px);
  right: -22px;
  width: 44px;
  height: 44px;
  z-index: 10;
  border: 10px solid #ffc718;
  box-shadow: 20px 35px 62px rgba(255, 199, 24, 0.62);
  border-radius: 5px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

@media (max-width: 1382px) {
  /* .section2-leftside-box {
    display: none;
  }
  .section2-rightside-box {
    display: none;
  } */
}

@media (max-width: 768px) {
  .section2-card {
    width: 100%;
    padding: 12px;
  }

  .section2-btn {
    margin: 8px;
    padding: 0.7em 3.2em;
  }

  .section2-main-container {
    padding: 8px;
    margin: auto;
    width: 95%;
  }
  .section2-heading__title2 {
    font-size: 30px;
  }
}

@media (max-width: 500px) {
  .title-shadow-design {
    font-size: 32px;
  }

  .section2-leftside-box,
  .section2-rightside-box {
    -webkit-transform: scale(0.5) rotate(45deg);
            transform: scale(0.5) rotate(45deg);
  }

  .section2-input-container {
    padding: 16px;
  }
  .section2-input-container h6 {
    font-size: 20px;
  }
  .section2-mint-cost h1 {
    font-size: 24px;
  }
  .section4 .title-shadow-design {
    line-height: 80px;
    font-size: 45px !important;
  }
}

.section3 {
  color: white;
  margin-top: 54px;
  display: flex;
  justify-content: center;
}

.section3-maincontainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* padding:20px; */
  padding-top: 50px;
  width: 80%;
  /* background-color: blue; */
}

.section3-heading2 {
  margin: auto;
  font-family: Eczar;
  font-style: normal;
  font-weight: bold;
  font-size: 75px;

  color: #ffc718;
}

.section3-heading4 {
  margin: auto;
  font-family: Eczar;
  font-style: normal;
  font-weight: bold;
  font-size: 75px;
  text-align: center;
  margin-bottom: 80px;
  color: #ffc718;
}

.section3-content-container1 {
  display: flex;
  margin-bottom: 125px;
  width: 100%;
}

.section-2-vector {
  border: 24px solid #ffc718;
  -webkit-filter: blur(7px);
          filter: blur(7px);
  -webkit-transform: rotate(40deg);
          transform: rotate(40deg);
  position: absolute;
  width: 135.23px;
  height: 135.23px;
  box-shadow: 20px 35px 62px rgb(255 199 24 / 35%);
  top: 100px;
}

.section3-image-container1-maindiv {
  width: 40%;
  margin-bottom: 20px;
}

.section3-image-container1 {
  color: white;
  width: 264px;
  height: 327px;
  object-fit: contain;
  max-height: 500px;
  position: relative;
  border: 1px solid #e9ac07;
  box-sizing: border-box;
  border-radius: 10px;
}

.section3-image-container1:before {
  content: '';
  height: 400px;
  width: 220px;
  position: absolute;
  top: 0px;
  right: -110px;
  background-image: url(/static/media/Ruby.fda24fb9.png);
  background-position: center;
  background-size: 400px;
  background-repeat: no-repeat;
}

.section-3-line {
  width: 77px;
  height: 0px;
  border: 1px solid #e6ad05;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  display: block;
  margin: 16px 0px 50px;
}

.section3-para-container1 {
  /* padding: 20px; */
  width: 60%;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 34px;
  /* or 172% */

  text-align: justify;
  letter-spacing: 0.4px;

  color: #bdbdbd;
}

.section3-image-container2-maindiv {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.section3-image-container2-maindiv .section-2-vector {
  bottom: -150px;
  top: auto;
  right: 22vw;
  -webkit-filter: none;
          filter: none;
  border-radius: 4px;
}

.section3-content-container2 {
  display: flex;
  /* margin: 30px; */
  /* padding: 20px; */
  width: 100%;
  margin-bottom: 225px;
}


.section2-sidepillar {
  position: absolute;
  width: 568.56px;
  height: 1907.3px;
  left: -200px;
  top: -450px;
  background: url(/static/media/pillar2.c1e2b0e1.png);
  background-repeat: no-repeat;
  -webkit-filter: blur(1px);
          filter: blur(1px);
  -webkit-transform: rotate(-10deg);
          transform: rotate(-10deg);
  z-index: 10;
}

@media (max-width: 1000px) {
  .section2-sidepillar {
    display: none;
  }
}

.section3-image-container2 {
  color: white;
  width: 264px;
  height: 327px;
  object-fit: contain;
  max-height: 500px;
  border: 1px solid #e9ac07;
  position: relative;
  box-sizing: border-box;
  border-radius: 10px;
}

.section3-image-container2:before {
  content: '';
  height: 400px;
  width: 220px;
  position: absolute;
  top: 0px;
  left: -110px;
  background-image: url(/static/media/emerald.42174424.png);
  background-position: center;
  background-size: 400px;
  background-repeat: no-repeat;
}

.section3-para-container2 {
  /* padding: 20px; */
  width: 50%;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: justify;
  letter-spacing: 0.4px;

  color: #bdbdbd;
}

@media (max-width: 900px) {
  .section3-maincontainer {
    padding: 0px;
  }

  .section3-content-container1 {
    flex-direction: column;
    margin-bottom: 16px;
  }
  .section3-content-container2 {
    flex-direction: column-reverse;
  }

  .section3-image-container1-maindiv {
    display: flex;
    min-width: 200px;
    padding: 0px;
    margin: 32px auto 80px;
  }

  .section3-image-container1 {
    max-height: 1000px;
  }
  .section3-para-container1 {
    text-align: center;
    width: 100%;
    padding: 0px;
  }

  .section3-image-container2-maindiv {
    min-width: 200px;
    margin: 32px auto 80px;
  }

  .section3-image-container2 {
    max-height: 1000px;
  }
  .section3-para-container2 {
    text-align: center;
    width: 100%;
    padding: 0px;
  }

  .section3-heading {
    text-align: center;
  }
}

@media (max-width: 480px) {
  .section3-image-container1-maindiv {
    margin-right: 120px;
  }
  .section3-image-container2-maindiv {
    margin-left: 120px;
    margin-bottom: 100px;
  }

  .section-3-line{
    margin-right: auto;
    margin-left: auto;
  }
  .section3-content-container2{
    margin-bottom: 50px;
  }
  .section3-image-container2-maindiv .section-2-vector{
    bottom: -250px;
  }

  .section-2-vector{
    display: none;
  }
}

.section3-secondsubsection-container2 {
  height: 200px;
  width: 220px;
  position: absolute;
  top: -60px;
  right: -110px;
  border: 1px solid #e9ac07;
  z-index: 0;
}

.section3-secondsubsection1 {
  width: 450px;
  height: 450px;
  position: relative;
  margin: 20px;
}
.section3-secondsubsection1-box1 {
  width: 300px;
  height: 300px;
  position: absolute;
  top: -20px;
  right: -50px;
  opacity: 0.8; /* for demo purpose  */
  border: 1.1661px solid #e9ac07;
  box-sizing: border-box;
  border-radius: 11.661px;
}
.section3-border-bottom{
  width: 300px;
  height: 300px;
  position: absolute;
  bottom: -50px;
  left: -25px;
  opacity: 0.2;
  border: 1.13614px solid #E9AC07;
  box-sizing: border-box;
  border-radius: 11.3614px;
}

.section3-secondsubsection1-box2 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* opacity: 0.8;  for demo purpose  */

  background-image: url("/static/media/Cinema 4D bis.dd2de69e.jpg");
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
}

.section3-secondsubsection1-box3 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* opacity: 0.8;  for demo purpose  */

  background-image: url("/static/media/Cinema 4D.60d45686.jpg");
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
}

.section3-secondsubsection1-stack-top {
  z-index: 9;
  margin: 20px; /* for demo purpose  */
  border-radius: 12px;
}

.section3-maindiv-secondsubsection {
  display: flex;
  justify-content: space-evenly;
}

.section3-subsection-container {
  width: 50%;
  margin-right: 32px;
}

.section3-maindiv-subsection{
  position: relative;
  margin-bottom: 100px;
}

@media (max-width: 1000px) {
  .section3-maindiv-secondsubsection {
    flex-direction: column;
  }

  .section3-maindiv-subsection {
    display: flex;
    justify-content: center;
  }

  .section3-secondsubsection1 {
    margin: auto;
    margin-bottom: 50px;
  }

  .section3-subsection-container p {
    margin-bottom: 50px;
    padding: 20px;
  }

  .section3-subsection-container {
    width: 90%;
  }
}

@media (max-width: 530px) {
  .section3-secondsubsection1 {
    max-width: 300px;
    max-height: 300px;
  }

  .section3-secondsubsection1-box1 {
    max-width: 200px;
    max-height: 200px;
  }

  .section3{
    margin-top: 24px;
  }
  .section3-subsection-container p{
    margin-top: 60px;
    margin-left: 16px;
    padding: 8px;
  }
  .section3-maindiv-subsection{
    margin-bottom: 8px;
  }
}

@media (max-width: 403px) {
  .section3-secondsubsection1 {
    margin-left: -10px;
  }
}

.section3-secondsubsection-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 34px;
  text-align: justify;
  color: #a6a6a6;
  max-width: 450px;
  margin-top: 120px;
  margin-left: 32px;
}

@media (max-width: 800px) {
  .section3-main-container {
    width: 100%;
  }
  .section3-heading2 {
    line-height: 24vw;
  }
  .section3-heading4 {
    font-size: 30px;
  }
  .section3-maincontainer {
    width: 95%;
  }
}

.section4 {
  color: white;
  margin-bottom: 50px;
  margin-top: 32px;
}

.section4-maincontainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 90%;
  max-width: 1400px;
  margin: auto;
}

.diamond-shape {
  height: 260px;
  text-align: center;
  border: 1.1661px solid #e9ac07;
  box-sizing: border-box;
  border-radius: 20px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  width: 260px;
  margin: 80px;
  margin-bottom: 120px;
}
.item-count {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 46px;
  line-height: 69px;
  /* identical to box height */

  color: #ffc718;
  display: table-cell;
  height: 260px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  vertical-align: middle;
  width: 260px;
}

.section4-card {
  position: absolute;
  text-align: justify;
  top: 135px;
  left: -135px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  width: 320px;
  height: 175px;
  padding: 20px;
  background: rgba(0, 0, 0, 0.2);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  border-radius: 25px;
  font-size: 20px;
  line-height:32px
}

.section4-border {
  border-bottom: 1px solid #ddac74;
}
.section4-mainheading1 {
  text-align: center;
  margin-bottom: 50px;
  font-family: Eczar;
  font-style: normal;
  font-weight: bold;
  font-size: 75px;
  color: #ffc718;
}

.section4-mainheading2 {
  position: absolute;
  height: 115px;
  left: 677px;
  top: 3263px;
  font-size: 113px;
  /* identical to box height, or 115% */
  display: flex;
  font-family: Eczar;
  font-style: normal;
  font-weight: bold;
  word-spacing: -5px;
  /* identical to box height, or 164% */

  color: rgba(30, 37, 44, 0.0001);

  opacity: 0.1;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ffc718;
}

.number-counter {
  position: absolute;
  width: 104.65px;
  height: 104.65px;
  right: 4px;
  top: 4px;
  background: #0c0c0c;
  border: 1.1661px solid rgba(233, 172, 7, 0.24);
  box-sizing: border-box;
  border-radius: 15px;
}

.number-counter > span {
  font-size: 50px;
  font-weight: 900;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 9px;
  margin-left: 40px;
}

@media (max-width: 1600px) {
  .section4-mainheading2 {
    left: 572px;
  }
}

@media (max-width: 1382px) {
  .section4-mainheading2 {
    display: none;
  }
}

@media (max-width: 900px) {
  .section4 {
    margin-left: 30px;
    margin-right: 30px;
  }
}

@media (max-width: 800px) {
  .diamond-shape {
    margin-bottom: 140px;
  }
}

@media (max-width: 500px) {
  .section4-mainheading1 {
    font-size: 38px !important;
  }

  .section4-maincontainer {
    width: 100%;
  }

  .diamond-shape {
    margin: 30px;
    margin-bottom: 140px;
  }
}

.section4-image-responsive {
  max-width: 100%;
  height: auto;
}

.section4-image-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.section5 {
  color: white;
  margin: 32px auto 50px auto;
  width: 85%;
}

.section5 .title-shadow-design,
.section5 .section5-mainheading1 {
  text-align: left;
}

.underline-heading {
  border-top: solid orange 2px;
  width: 100px;
}

.section5-maincontainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 100px;
  justify-content: center;
  padding-bottom: 250px;
}

.section5-main-team {
  position: relative;
}

.section5-main-team .img-container {
  max-width: 430px;
  border-radius: 13.4157px;
  padding: 32px;
  position: absolute;
  top: 10%;
  left: 5%;
}

.section5-main-team .img-container img {
  background: #1e1a1a;
  border-radius: 13.4157px;
  padding: 32px;
  width:100%
}

.img-container h3{
  font-family: Poppins;
  font-weight: bold;
  margin-top: 32px;
  font-size: 25px;
}

.img-container h3 span{
  font-size: 18px;
}

.img-container p{
  font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 18px !important;
line-height: 29px;
text-align: justify;

color: #727272;
}

.section5-main-team h3 span {
  font-size: 20px;
}

.orange-frame {
  width: 235px;
  height: 295px;
  border: solid #ffc718 1px;
  border-radius: 12px;
}

.orange-frame p {
  text-transform: uppercase;
  font-weight: 500;
  margin-top: 10px;
  font-size: 14px;
  margin-left: 32px;
  font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 17px;
letter-spacing: 2.68314px;
text-transform: uppercase;
color: #8D8F91;


}

.section5-border {
  border-bottom: 1px solid #ddac74;
}
.section5-mainheading1 {
  text-align: center;
  margin-bottom: 0;
  font-family: Eczar;
  font-style: normal;
  font-weight: bold;
  font-size: 75px;
  color: #ffc718;
}

@media screen and (max-width: 768px) {
  .section5 {
    margin-top: 50px;
    margin-bottom: 0px;
  }
  .section5 .section5-mainheading1,
  .section5 .title-shadow-design {
    text-align: center;
  }

  .section5 .title-shadow-design {
    font-size: 50px !important;
    line-height: 60px;
  }

  .underline-heading {
    margin: auto;
  }

  .section5-main-team {
    margin-bottom: 350px;
    text-align: center;
  }

  .orange-frame p {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 15px;
  }

  .section5-main-team h3,
  .section5-main-team p {
    width: 100%;
    text-align: left;
    margin: auto;
  }
  
  .section5-main-team h3{
    margin-top: 50px;
  }


  .section5-main-team img {
    width: 100%;
  }

  .orange-frame p {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 15px;
  }

  .orange-frame {
    width: 75%;
    left: 32%;
  }

  .section5-maincontainer{
    padding-bottom: 0px;
  }

  .section5-main-team .img-container img{
    width:280px;
  }
  .img-container{
    left:0px !important;
  }
  .section5-mainheading1{
    font-size: 38px;
  }

  .section5-main-team-m{
    padding-bottom: 32px;
  }
}

.footer-maincontainer{
    margin-top: 150px;
    border-top: 1px solid #ddac74;
    color: white;
}
.navbar-nav-icons-mobile{
    display: none;
}
@media screen and (max-width: 768px) {
    .footer-maincontainer{
        margin-top: 50px;
    }

    .navbar-nav-icons-mobile{
        display: flex;
        flex-direction: row;
        margin:auto;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }

}


  #back-to-top-button {
    display: inline-block;
    background-color: #B5864F;
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 4px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    transition: background-color .3s, 
      opacity .5s, visibility .5s;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
  }
  #back-to-top-button::after {
    content: "\f077";
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    line-height: 40px;
    color: #fff;
  }
  #back-to-top-button:hover {
    cursor: pointer;
    background-color: #333;
  }
  #back-to-top-button:active {
    background-color: #555;
  }
  #back-to-top-button.show-back-to-top-button {
    opacity: 1;
    visibility: visible;
  }


  @media (min-width: 500px) {
    #button {
      margin: 30px;
    }
  }
