.section1 {
  color: white;
  object-fit: contain;
  height: 891px;
}

.section1__contents {
  display: flex;
  justify-content: center;
  height: 140px;
}

.section1__description {
  color: #cfa26f;
  text-align: center;
  font-size: 18px;
}

.section1__fadeBottom {
  height: calc(100vh - 140px);
  background-image: linear-gradient(
    180deg,
    transparent,
    rgba(19, 19, 19, 0.30),
    rgba(19, 19, 19, 0.30)
  );
}

.section1-glasseffect {
  margin: 16px;
  margin-top: 550px;
  max-height: 16rem;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 24px;
  z-index: 1;
  background: rgba(11, 11, 11, 0.28);
  border: 2px solid rgba(255, 199, 24, 0.2);
  box-sizing: border-box;
  backdrop-filter: blur(6px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 14.2839px;
  width: 643px;
  height: 263px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section1-secondsubsection1 {
  max-width: 400px;
  height: 400px;
  position: relative;
  margin: 20px;
}
.section1-secondsubsection1-box1 {
  width: 300px;
  height: 300px;
  position: absolute;
  top: 200px;
  right: 40px;
  opacity: 0.8; /* for demo purpose  */
  /* border: 1.1661px solid #E9AC07; */
  box-sizing: border-box;
  border-radius: 11.661px;

  background-image: url('../../assets/images/pillar1.png');
  background-size: contain;
  /* mix-blend-mode: hard-light; */
  opacity: 1;
  z-index: 0;
  background-position: center;
  background-repeat: no-repeat;

  /* -webkit-mask-image: url("../../assets/images/Mask-Group4.png");
mask-image: url("../../assets/images/Mask-Group4.png"); */
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  filter: saturate(150%);
  filter: brightness(100%);
}

.section1-secondsubsection1-box2 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: -10px;
  left: -200px;
  /* opacity: 0.8;  for demo purpose  */

  background-image: url('../../assets/images/Mask-Group3.png');
  background-position: center;
  background-repeat: no-repeat;
  width: 792px;
  height: 554px;
  mix-blend-mode: screen;
  transform: matrix(-1, 0, 0, 1, 0, 0);

  -webkit-mask-image: url('../../assets/images/Mask-Group4.png');
  mask-image: url('../../assets/images/Mask-Group4.png');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;

  transform: rotate(-290deg);
}

.section1-secondsubsection1-box3 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: -120px;
  left: -20px;

  background-image: url('../../assets/images/phallus.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  mix-blend-mode: screen;
}

.section1-secondsubsection1-stack-top {
  z-index: 9;
  margin: 20px; /* for demo purpose  */
}

@media (max-width: 436px) {
  .section1-glasseffect {
    width: 100%;
  }

  .section1-secondsubsection1-box1 {
    left: -30px;
  }
}

.section1-square-box1 {
  position: absolute;
  width: 30.23px;
  height: 30.23px;
  /* right: 0px; */
  top: 381px;

  border: 4px solid #ffd44d;
  filter: blur(4px);
  border-radius: 5px;
  transform: rotate(54.58deg);
}

.section1-square-box2 {
  position: absolute;
  width: 70.23px;
  height: 70.23px;
  right: 20px;
  top: 381px;

  border: 8px solid #ffd44d;
  filter: blur(5px);
  border-radius: 5px;
  transform: rotate(54.58deg);
}

@media (max-width: 1000px) {
  .section1-square-box1 {
    display: none;
  }

  .section1-square-box2 {
    display: none;
  }
}

@media (max-width: 480px) {
  .section1{
    height:500px;
  }
}
