* {
    margin: 0;
  }
  
.app {
    /* background-color: #111;
    background-image: url('./assets/images/bg1.png'); */
    background: linear-gradient(rgba(19, 19, 19, 0.9),rgba(19, 19, 19, 0.9)
  ), url('./assets/images/bg1.png') no-repeat center center;
  background-size: cover;
  }

  /* html{
    width: fit-content;
  } */

  @media
only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (-o-min-device-pixel-ratio: 3/2),
only screen and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-device-pixel-ratio: 1.5){

  html,
  body{
    width:100%;
    overflow-x:hidden;
  }

}

a{
  text-decoration: none;
}
.bg{
  
  background-image: url('./assets/images/bg1.png');
  opacity: 0.4;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  filter: brightness(25%);
  width: 100%;
  height: 948px;
  position: absolute;
  background-size: cover;
}

@media (max-width: 768px) {

  .title-shadow-design{
    font-size: 8vw !important;
    margin-bottom: -42px !important;
  }
  .section3-maincontainer .title-shadow-design{
    font-size: 7vw !important;
    margin-bottom: -16px !important;
  }
  .section3-para-container1 .title-shadow-design{
    font-size: 8vw !important;
    margin-bottom: -44px !important;
  }
  .section3-heading4,
  .section3-heading2,
  .section2-heading__title2,
  .section4-mainheading1{
    font-size:7vw !important;
  }
}