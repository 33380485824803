

  #back-to-top-button {
    display: inline-block;
    background-color: #B5864F;
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 4px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    transition: background-color .3s, 
      opacity .5s, visibility .5s;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
  }
  #back-to-top-button::after {
    content: "\f077";
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    line-height: 40px;
    color: #fff;
  }
  #back-to-top-button:hover {
    cursor: pointer;
    background-color: #333;
  }
  #back-to-top-button:active {
    background-color: #555;
  }
  #back-to-top-button.show-back-to-top-button {
    opacity: 1;
    visibility: visible;
  }


  @media (min-width: 500px) {
    #button {
      margin: 30px;
    }
  }