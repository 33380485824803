@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Eczar:wght@700&family=Poppins:wght@300&display=swap');
/* The sticky class is added to the navbar with JS when it
reaches its scroll position */
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  width: 100%;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(15px);
}
.navbar {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  z-index: 100;
  /* animations */
  transition-timing-function: ease-in;
  transition: all 0.8s;
  padding: 50px 48px 32px;
  width: 100%;
}

.navbar-background-dark {
  background-color: rgba(17, 17, 17, 0.856);
}

.navbar-nav a {
  color: #fefffb !important;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  margin: 8px 24px 8px 0px;
  padding: 0px;
}

.header-social-link {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  border-radius: 50%;
  transition: all 0.3s;
  font-size: 0.9rem;
  text-decoration: none;
  margin-left: 30px;
  margin-top: 5px;
}

.header-social-link.twitter,
.telegram-plane {
  background-color: #16a2f1;
}

.header-social-link.discord {
  background-color: #7289da;
}

.header-social-link:hover,
.header-social-link:focus {
  background: #b5864f;
  text-decoration: none;
  color: #000 !important;
}

.header-btn {
  background: none;
  cursor: pointer;
  font: 700 1.2rem 'Roboto Slab', sans-serif;
  width:185px;
  height: 56px;
  letter-spacing: 0.05rem;
  border: 0.580038px solid #FFC144 !important;
  border-radius: 30px;
  color: #ffffbc;
  box-shadow: inset 11px 0px 0px -10px #ffc144, inset 11px 0px 0px -10px #ffc144;
  filter: brightness(1.2);
  font-family: Poppins;
}

.header-btn a {
  color: #ffffbc;

}

.header-btn:focus {
  outline: 2px dotted #b5864f !important;
}

.header-btn:hover {
  
  opacity: 0.8;
  transition: all 0.2s;
}

.navbar-brand {
  color: #ffc718 !important;
  font-family: Eczar;
  font-weight: bold;
  font-size: 25px;
  margin-right: 6rem;
  margin-left: 5rem;
}

.mobile-header-btn {
  display: none;
}

#social-links-header {
  margin-right: 4rem;
  flex-wrap: wrap;
}

#social-links-header a {
  margin-right: 16px;
}

#navbarSupportedContent .navbar-nav {
  flex-wrap: wrap;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 991px) {
  #social-links-header {
    display: none;
  }

  .mobile-header-btn {
    display: inline-block;
    margin: 24px 0px;
  }

  .desktop-header-btn {
    display: none;
  }

  .navbar {
    background-color: rgba(17, 17, 17, 0.9);
    border-radius: 24px;
    padding: 16px 32px;
  }

  .navbar-toggler {
    background-color: #ffc718;
    position: absolute;
    right: 32px;
    top: 16px;
  }
  .sticky {
    border-bottom: 1px solid #ddac74;
  }
  .navbar-brand{
    margin-left: 0px;
  }
}
