.section3 {
  color: white;
  margin-top: 54px;
  display: flex;
  justify-content: center;
}

.section3-maincontainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* padding:20px; */
  padding-top: 50px;
  width: 80%;
  /* background-color: blue; */
}

.section3-heading2 {
  margin: auto;
  font-family: Eczar;
  font-style: normal;
  font-weight: bold;
  font-size: 75px;

  color: #ffc718;
}

.section3-heading4 {
  margin: auto;
  font-family: Eczar;
  font-style: normal;
  font-weight: bold;
  font-size: 75px;
  text-align: center;
  margin-bottom: 80px;
  color: #ffc718;
}

.section3-content-container1 {
  display: flex;
  margin-bottom: 125px;
  width: 100%;
}

.section-2-vector {
  border: 24px solid #ffc718;
  filter: blur(7px);
  transform: rotate(40deg);
  position: absolute;
  width: 135.23px;
  height: 135.23px;
  box-shadow: 20px 35px 62px rgb(255 199 24 / 35%);
  top: 100px;
}

.section3-image-container1-maindiv {
  width: 40%;
  margin-bottom: 20px;
}

.section3-image-container1 {
  color: white;
  width: 264px;
  height: 327px;
  object-fit: contain;
  max-height: 500px;
  position: relative;
  border: 1px solid #e9ac07;
  box-sizing: border-box;
  border-radius: 10px;
}

.section3-image-container1:before {
  content: '';
  height: 400px;
  width: 220px;
  position: absolute;
  top: 0px;
  right: -110px;
  background-image: url('../../assets/images/Ruby.png');
  background-position: center;
  background-size: 400px;
  background-repeat: no-repeat;
}

.section-3-line {
  width: 77px;
  height: 0px;
  border: 1px solid #e6ad05;
  transform: rotate(180deg);
  display: block;
  margin: 16px 0px 50px;
}

.section3-para-container1 {
  /* padding: 20px; */
  width: 60%;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 34px;
  /* or 172% */

  text-align: justify;
  letter-spacing: 0.4px;

  color: #bdbdbd;
}

.section3-image-container2-maindiv {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.section3-image-container2-maindiv .section-2-vector {
  bottom: -150px;
  top: auto;
  right: 22vw;
  filter: none;
  border-radius: 4px;
}

.section3-content-container2 {
  display: flex;
  /* margin: 30px; */
  /* padding: 20px; */
  width: 100%;
  margin-bottom: 225px;
}


.section2-sidepillar {
  position: absolute;
  width: 568.56px;
  height: 1907.3px;
  left: -200px;
  top: -450px;
  background: url('../../assets/images/pillar2.png');
  background-repeat: no-repeat;
  filter: blur(1px);
  transform: rotate(-10deg);
  z-index: 10;
}

@media (max-width: 1000px) {
  .section2-sidepillar {
    display: none;
  }
}

.section3-image-container2 {
  color: white;
  width: 264px;
  height: 327px;
  object-fit: contain;
  max-height: 500px;
  border: 1px solid #e9ac07;
  position: relative;
  box-sizing: border-box;
  border-radius: 10px;
}

.section3-image-container2:before {
  content: '';
  height: 400px;
  width: 220px;
  position: absolute;
  top: 0px;
  left: -110px;
  background-image: url('../../assets/images/emerald.png');
  background-position: center;
  background-size: 400px;
  background-repeat: no-repeat;
}

.section3-para-container2 {
  /* padding: 20px; */
  width: 50%;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: justify;
  letter-spacing: 0.4px;

  color: #bdbdbd;
}

@media (max-width: 900px) {
  .section3-maincontainer {
    padding: 0px;
  }

  .section3-content-container1 {
    flex-direction: column;
    margin-bottom: 16px;
  }
  .section3-content-container2 {
    flex-direction: column-reverse;
  }

  .section3-image-container1-maindiv {
    display: flex;
    min-width: 200px;
    padding: 0px;
    margin: 32px auto 80px;
  }

  .section3-image-container1 {
    max-height: 1000px;
  }
  .section3-para-container1 {
    text-align: center;
    width: 100%;
    padding: 0px;
  }

  .section3-image-container2-maindiv {
    min-width: 200px;
    margin: 32px auto 80px;
  }

  .section3-image-container2 {
    max-height: 1000px;
  }
  .section3-para-container2 {
    text-align: center;
    width: 100%;
    padding: 0px;
  }

  .section3-heading {
    text-align: center;
  }
}

@media (max-width: 480px) {
  .section3-image-container1-maindiv {
    margin-right: 120px;
  }
  .section3-image-container2-maindiv {
    margin-left: 120px;
    margin-bottom: 100px;
  }

  .section-3-line{
    margin-right: auto;
    margin-left: auto;
  }
  .section3-content-container2{
    margin-bottom: 50px;
  }
  .section3-image-container2-maindiv .section-2-vector{
    bottom: -250px;
  }

  .section-2-vector{
    display: none;
  }
}

.section3-secondsubsection-container2 {
  height: 200px;
  width: 220px;
  position: absolute;
  top: -60px;
  right: -110px;
  border: 1px solid #e9ac07;
  z-index: 0;
}

.section3-secondsubsection1 {
  width: 450px;
  height: 450px;
  position: relative;
  margin: 20px;
}
.section3-secondsubsection1-box1 {
  width: 300px;
  height: 300px;
  position: absolute;
  top: -20px;
  right: -50px;
  opacity: 0.8; /* for demo purpose  */
  border: 1.1661px solid #e9ac07;
  box-sizing: border-box;
  border-radius: 11.661px;
}
.section3-border-bottom{
  width: 300px;
  height: 300px;
  position: absolute;
  bottom: -50px;
  left: -25px;
  opacity: 0.2;
  border: 1.13614px solid #E9AC07;
  box-sizing: border-box;
  border-radius: 11.3614px;
}

.section3-secondsubsection1-box2 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* opacity: 0.8;  for demo purpose  */

  background-image: url('../../assets/images/Cinema\ 4D\ bis.jpg');
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
}

.section3-secondsubsection1-box3 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* opacity: 0.8;  for demo purpose  */

  background-image: url('../../assets/images/Cinema\ 4D.jpg');
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
}

.section3-secondsubsection1-stack-top {
  z-index: 9;
  margin: 20px; /* for demo purpose  */
  border-radius: 12px;
}

.section3-maindiv-secondsubsection {
  display: flex;
  justify-content: space-evenly;
}

.section3-subsection-container {
  width: 50%;
  margin-right: 32px;
}

.section3-maindiv-subsection{
  position: relative;
  margin-bottom: 100px;
}

@media (max-width: 1000px) {
  .section3-maindiv-secondsubsection {
    flex-direction: column;
  }

  .section3-maindiv-subsection {
    display: flex;
    justify-content: center;
  }

  .section3-secondsubsection1 {
    margin: auto;
    margin-bottom: 50px;
  }

  .section3-subsection-container p {
    margin-bottom: 50px;
    padding: 20px;
  }

  .section3-subsection-container {
    width: 90%;
  }
}

@media (max-width: 530px) {
  .section3-secondsubsection1 {
    max-width: 300px;
    max-height: 300px;
  }

  .section3-secondsubsection1-box1 {
    max-width: 200px;
    max-height: 200px;
  }

  .section3{
    margin-top: 24px;
  }
  .section3-subsection-container p{
    margin-top: 60px;
    margin-left: 16px;
    padding: 8px;
  }
  .section3-maindiv-subsection{
    margin-bottom: 8px;
  }
}

@media (max-width: 403px) {
  .section3-secondsubsection1 {
    margin-left: -10px;
  }
}

.section3-secondsubsection-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 34px;
  text-align: justify;
  color: #a6a6a6;
  max-width: 450px;
  margin-top: 120px;
  margin-left: 32px;
}

@media (max-width: 800px) {
  .section3-main-container {
    width: 100%;
  }
  .section3-heading2 {
    line-height: 24vw;
  }
  .section3-heading4 {
    font-size: 30px;
  }
  .section3-maincontainer {
    width: 95%;
  }
}
