.Countdown {
  margin: 20px auto;
  padding-bottom: 16px;
}

.Countdown-col {
  display: inline-block;
}

.Countdown-col-element {
  display: inline-block;
  display: flex;
  flex-direction: column;
  width: 115px;
  align-items: center;
}

.Countdown-col-element strong {
  font-size: 65px;
  line-height: 65px;
  font-family: Eczar;
}

.Countdown-col-element span {
  color: #cfa26f;
  font-size: 20px;
  font-family: Poppins;
  font-weight: 700;
}

@media (max-width: 480px) {
  .section1-glasseffect {
    padding: 16px;
    margin-top: 260px;
    height:175px;
  }
  .Countdown-col-element {
    width: 19.5vw;
  }
  .Countdown-col-element strong {
    font-size: 10vw;
  }
  .Countdown-col-element span{
    font-size: 4vw;
  }
  .section1__description{
    font-size: 16px;
  }
  .Countdown{
    margin: 12px auto;
  }
}
