.section2 {
  color: white;
  display: flex;
  justify-content: center;
  margin-top: -20px;
}

.section2-main-container {
  width: 80%;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.6);
  padding: 30px;
  background: rgba(0, 0, 0, 0.21);
  backdrop-filter: blur(15px);
  border-radius: 14.2839px;
}

/* HEADING */

.section2-heading {
  text-align: center;
  margin-top: 28px;
}

.title-shadow-design {
  /* MINT LUCKY PHALLUS */
  font-family: Eczar;
  font-weight: bold;
  font-size: 80px;
  word-spacing: -5px;
  color: rgba(30, 37, 44, 0.0001);
  opacity: 0.2;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ffc718;
  margin-bottom: -60px;
  text-align: center;
}

.section3-para-container1 .title-shadow-design {
  font-size: 86px;
  /* margin-left: -32px; */
}

.section4 .title-shadow-design,
.section3-maincontainer .title-shadow-design {
  font-size: 84px;
}
.section2-heading__title2 {
  font-family: Eczar;
  font-style: normal;
  font-weight: bold;
  font-size: 45px;
  line-height: 80px;
  color: #ffc718;
}

.section2-heading__credits {
  transition: all 0.5s;
  color: #c69b6c;
}

.section2-heading__credits span {
  font-size: 22px;
  font-weight: 400;
  line-height: 33px;
}

.section2-heading__credits-contract {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.78);
  opacity: 0.56;
  border: 0.580038px solid #3d3a34;
  box-sizing: border-box;
  box-shadow: 0px 17px 47px rgba(255, 199, 25, 0.15);
  border-radius: 52.2034px;
  width: 140px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  color: #c69b6c;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
}

.section2-heading__credits-contract:hover {
  color: #c69b6c;
}

.section2-heading__credits .heading__link {
  color: inherit;
}

/* CARDS */

.section2-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1090px;
  margin: auto;
}

.section2-card {
  margin-top: 40px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  width: 80%;
  min-height: 200px;
  grid-template-rows: 20px 50px 1fr 50px;
  border-radius: 10px;
  transition: all 0.2s;
}

.section2-card__apply {
  align-self: center;
}

/* section2-CARD BACKGROUNDS */

.section2-card-4 {
  /* background: radial-gradient(#505050, #1a1919); */
  position: relative;
  margin-top: 8px;
  width: 100%;
}

/* RESPONSIVE */

@media (max-width: 1600px) {
  .section2-cards {
    justify-content: center;
  }
}
input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  outline: none;
  background-color: transparent;
  width: 100%;
  color: #b5864f; /* change [input cursor color] by this*/
  text-shadow: 0px 0px 0px #fff; /* change [input font] by this*/
  -webkit-text-fill-color: transparent;
}

.section2-input-container {
  /* border: 2px solid #505050; */
  background-color: #34343471;
  /* display: flex; */
  padding: 24px;
  border-radius: 15px;
  margin: 12px 0px 20px;
}

.section2-input-container h6 {
  color: #c69b6c;
  font-size: 22px;
}

.section2-buttons {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section2-button {
  cursor: pointer;
  color: #ffffbc !important;
  outline: none;
  font-weight: 700;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  max-width: 767px;
  height: 56px;
  margin: auto;
  display: block;
  font-family: Poppins;
  font-size: 18px;
  width: 100%;
  background: rgba(0, 0, 0, 0.78);
  opacity: 0.56;
  border: 0.580038px solid #ffc144;
  box-sizing: border-box;
  box-shadow: 0px 17px 47px rgba(255, 199, 25, 0.15);
  border-radius: 52.2034px;
}

.section2-button:hover {
  box-shadow: 0 0 10px rgb(245, 169, 28), 0 0 40px rgb(245, 169, 28),
    0 0 80px rgb(245, 169, 28);
}

.section2-btn {
  background: none;
  border: none;
  cursor: pointer;
  line-height: 1.5;
  font: 700 1.2rem 'Roboto Slab', sans-serif;
  padding: 0.75em 2em;
  letter-spacing: 0.05rem;
  margin: 0.5em;
  width: 13rem;
  border: 0.58px solid #ffc144 !important;
  border-radius: 30px;
}

.section2-btn i {
  font-size: 1.5rem;
  color: #f4e486;
}

.section2-btn:hover {
  opacity: 0.8;
}

.section-2-text {
  text-align: center;
  font-size: 22px;
  font-family: Poppins;
  font-weight: 600;
  margin: 0px;
  color: #c69b6c !important;
}

.section2-mint-cost h1 {
  color: #f0e49d;
  font-family: Eczar;
}

.section2-mint-cost p {
  color: #b0b0b0;
  font-family: Poppins;
  font-size: 17px;
  line-height: 25px;
}

.section2-sidepillar {
  position: absolute;
  width: 568.56px;
  height: 1907.3px;
  left: -117.69px;
  top: 713px;
  background: url('../../assets/images/pillar2.png');
  background-repeat: no-repeat;
  filter: blur(3px);
  transform: rotate(7deg);
  z-index: 10;
}

@media (max-width: 1000px) {
  .section2-sidepillar {
    display: none;
  }
}

.section2-leftside-box {
  /* Vector */

  position: absolute;
  top: calc(50% - 62.5px);
  left: -62.5px;
  width: 125px;
  height: 125px;
  z-index: 10;
  border: 24px solid #ffc718;
  box-shadow: 20px 35px 62px rgba(255, 199, 24, 0.35);
  border-radius: 5px;
  transform: rotate(45deg);
}

.section2-rightside-box {
  position: absolute;
  top: calc(50% - 22px);
  right: -22px;
  width: 44px;
  height: 44px;
  z-index: 10;
  border: 10px solid #ffc718;
  box-shadow: 20px 35px 62px rgba(255, 199, 24, 0.62);
  border-radius: 5px;
  transform: rotate(-45deg);
}

@media (max-width: 1382px) {
  /* .section2-leftside-box {
    display: none;
  }
  .section2-rightside-box {
    display: none;
  } */
}

@media (max-width: 768px) {
  .section2-card {
    width: 100%;
    padding: 12px;
  }

  .section2-btn {
    margin: 8px;
    padding: 0.7em 3.2em;
  }

  .section2-main-container {
    padding: 8px;
    margin: auto;
    width: 95%;
  }
  .section2-heading__title2 {
    font-size: 30px;
  }
}

@media (max-width: 500px) {
  .title-shadow-design {
    font-size: 32px;
  }

  .section2-leftside-box,
  .section2-rightside-box {
    transform: scale(0.5) rotate(45deg);
  }

  .section2-input-container {
    padding: 16px;
  }
  .section2-input-container h6 {
    font-size: 20px;
  }
  .section2-mint-cost h1 {
    font-size: 24px;
  }
  .section4 .title-shadow-design {
    line-height: 80px;
    font-size: 45px !important;
  }
}
